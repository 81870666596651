import { getDarkColor } from '../utils/color';

export const hashCode = (s) => {
  if (!s) {
    return 0;
  }

  let h = 0;
  const l = s.length;
  let i = 0;
  if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
  return h;
};

export const getLetters = (name) =>
  (name || '')
    .trim()
    .split(/\s+/)
    .map((v) => v[0] || '')
    .filter((v) => /[a-zA-Z]/.exec(v))
    .join('')
    .toUpperCase()
    .substring(0, 2) || '';

export const getColorHash = (name) => {
  return Math.abs(Math.sin(hashCode(name)));
};

export const useBorderStyle = (name, color = null) => {
  const hash = color || getColorHash(name);
  const borderColor = getDarkColor(hash);

  return {
    borderLeftColor: borderColor,
    borderLeftWidth: 4,
    borderLeftStyle: 'solid',
  };
};
