import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { getDarkColor } from '../utils/color';
import { BoxProps, Flex } from '../grid';
import { getColorHash, getLetters, hashCode } from './shared';
interface AvatarProps {
  name?: string;
  image?: string;
  email?: string;
  size?: number;
  icon?: IconProp;
  color?: string;
}
export function Avatar({
  ref,
  name = '',
  image: imageUrl = '',
  email = '',
  size = 24,
  icon = null,
  color = null,
  sx = null,
  ...rest
}: AvatarProps & BoxProps) {
  const letters = getLetters(name) || name.substring(0, 2);
  const hash = getColorHash(name);
  const bg = color || getDarkColor(hash);
  let gravatarUrl = '';
  if (email) {
    gravatarUrl = `https://www.gravatar.com/avatar/${email}?d=404`;
  }

  // const [imageLoaded, setImageLoaded] = React.useState(false);

  // React.useEffect(() => {
  //   if (imageUrl) {
  //     const img = new Image();
  //     img.src = imageUrl;
  //     img.onload = () => {
  //       setImageLoaded(true);
  //     };
  //   }
  // }, [imageUrl]);

  // const [gravatarLoaded, setGravatarLoaded] = React.useState(false);

  // React.useEffect(() => {
  //   if (gravatarUrl) {
  //     const img = new Image();
  //     img.src = gravatarUrl;
  //     img.onload = () => {
  //       setGravatarLoaded(true);
  //     };
  //   }
  // }, [gravatarUrl]);

  // const hasImage = imageLoaded || gravatarLoaded;

  const hasImage = imageUrl;
  return <Flex ref={ref} alignItems="center" justifyContent="center" flexShrink={0} sx={{
    fontSize: `${0.45 * size}px`,
    fontWeight: 600,
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: '9999px',
    backgroundColor: !hasImage ? bg : 'background',
    color: 'white',
    overflow: 'hidden',
    objectFit: 'cover',
    // lineHeight: 1,

    ...(hasImage ? {
      backgroundPosition: 'center',
      backgroundImage: `${`url(${imageUrl})`} ${gravatarUrl ? `, url(${gravatarUrl})` : ''}`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderColor: 'gray.1',
      borderStyle: 'solid',
      borderWidth: '1px',
      boxShadow: 'medium'
    } : {}),
    ...sx
  }} {...rest} data-sentry-element="Flex" data-sentry-component="Avatar" data-sentry-source-file="index.tsx">
      {!hasImage && (icon ? <FontAwesomeIcon icon={icon} fixedWidth /> : letters)}
    </Flex>;
}