import { faChevronDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import React from 'react';
import { Link as ReachLink } from 'react-router-dom';
import axios from 'shared/anaxios';
import Button from 'shared/button';
import EmptyState from 'shared/empty-state';
import { Box, Flex, Grid } from 'shared/grid';
import Loader from 'shared/loader';
import settings from 'shared/settings';
import { CroppedText, Heading, Link, Text } from 'shared/typography';
import { toBase58 } from 'shared/utils/b58';
import { currencyFormat } from 'shared/utils/currency';
import { useAppSelector } from '../../store';
import { BorderBox } from '../shared';
import { formPost, payfastRedirect, paymentMeta } from 'shared/payments';
import { useLocationId } from '../../hooks';
import { useParams } from 'react-router';
const PayAccount = () => {
  const [paymentData, setPaymentData] = React.useState(null);
  const [openAccordion, setOpenAccordion] = React.useState(null);
  const [isProcessingPayment, setIsProcessingPayment] = React.useState(false);
  const [locationId, setLocationId] = useLocationId();
  const {
    clientId
  } = useParams();
  const [invoiceStatus, setInvoiceStatus] = React.useState('loading');
  const business = useAppSelector(state => state.businesses.data[state.public.businessId]);
  React.useEffect(() => {
    axios.get(`${settings.api2Root}/statement-payment-data/${clientId}`, {
      params: {
        location_id: locationId || undefined
      }
    }).then(({
      data
    }) => {
      setPaymentData(data);
      setInvoiceStatus('done');
    }).catch(() => {
      setInvoiceStatus('notfound');
    });
  }, [clientId, locationId]);
  if (invoiceStatus === 'loading') {
    return <Loader />;
  } else if (invoiceStatus === 'notfound') {
    return <EmptyState heading="Invoice not found" />;
  }
  const {
    total_amount,
    paid_amount,
    total_due,
    currency,
    payment_methods
  } = paymentData;
  return <Grid
  // flexGrow={1}
  gridTemplateAreas={[`
          'summary'
          'methods'
        `, `
          'methods summary'
        `]} gridTemplateColumns={['1fr', '1fr 300px']} data-sentry-element="Grid" data-sentry-component="PayAccount" data-sentry-source-file="component.tsx">
      {total_due > 0 ? <Box gridArea="methods">
          <BorderBox pt={0}>
            {payment_methods.some(p => paymentMeta[p.type]?.integrated) ? <>
                <Box px={3} py={4} borderBottomWidth="1px" borderBottomStyle="solid" borderBottomColor="gray.1">
                  <Heading fontSize={2} fontWeight={600}>
                    Choose a payment method
                  </Heading>
                </Box>

                {payment_methods.filter(p => p.show_on_invoice).filter(p => paymentMeta[p.type]?.integrated).map(({
            id,
            provider_data: {
              form_data,
              host,
              qrcode_url,
              qrcode_svg_url,
              client_id,
              payment_url
            },
            type,
            instructions
          }, idx) => <Box key={idx}>
                        <Grid p={3} gridGap={3} gridTemplateColumns={'auto 1fr auto'} sx={{
              '&:hover, &:active': {
                backgroundColor: 'gray.1'
              },
              cursor: 'pointer'
            }} onClick={() => {
              if (type === 'payfast') {
                setOpenAccordion(null);
                payfastRedirect(host, form_data);
              } else if (type == 'wigwag') {
                formPost(payment_url, {
                  location_id: locationId,
                  client_id: clientId,
                  payment_method_id: id
                });
              } else if (type == 'yoco') {
                formPost(payment_url, {
                  location_id: locationId,
                  client_id: clientId,
                  payment_method_id: id
                });
              } else {
                setOpenAccordion(openAccordion === idx ? null : idx);
              }
            }}>
                          <Flex alignItems="center">
                            {paymentMeta[type].icon}
                          </Flex>

                          <Flex alignItems="center">
                            <CroppedText fontFamily="heading" fontSize={2}>
                              {paymentMeta[type].name}
                            </CroppedText>
                          </Flex>

                          <Flex alignItems="center">
                            {paymentMeta[type].inPage && <FontAwesomeIcon icon={openAccordion === idx ? faChevronDown : faChevronRight} />}
                          </Flex>
                        </Grid>

                        <PayPalScriptProvider options={{
              clientId: client_id,
              currency,
              intent: 'capture'
            }}>
                          {openAccordion === idx && type == 'paypal' && <Box p={3}>
                              <PayPalButtons style={{
                  layout: 'horizontal'
                }} createOrder={(data, actions) => {
                  setIsProcessingPayment(true);
                  return actions.order.create({
                    intent: 'CAPTURE',
                    purchase_units: [{
                      amount: {
                        currency_code: currency,
                        value: total_due.toString()
                      }
                    }],
                    application_context: {
                      shipping_preference: 'NO_SHIPPING'
                    }
                  });
                }} onApprove={(details, data) => {
                  setIsProcessingPayment(true);
                  return axios.post(`${settings.api2Root}/client-paypal-transaction-complete/${id}`, {
                    order_id: details.orderID,
                    client_id: clientId,
                    location_id: locationId
                  }).then(() => {
                    setTimeout(() => {
                      axios.get(`${settings.api2Root}/statement-payment-data/${clientId}`, {
                        params: {
                          location_id: locationId || undefined
                        }
                      }).then(({
                        data
                      }) => {
                        setPaymentData(data);
                        setIsProcessingPayment(false);
                      });
                    }, 2000);
                  }).catch(() => setIsProcessingPayment(false));
                }} />

                              {/* {isProcessingPayment && <Loader />} */}
                            </Box>}
                        </PayPalScriptProvider>
                        {openAccordion === idx && type == 'snapscan' && <Box p={3}>
                            <Link href={qrcode_url}>
                              <img alt="qr code" src={qrcode_svg_url} />
                            </Link>
                          </Box>}

                        {openAccordion === idx && type == 'eft' && <Grid p={3}>
                            {instructions.split('\n').map((v, idx) => <CroppedText key={idx}>{v}</CroppedText>)}
                          </Grid>}
                      </Box>)}
              </> : <EmptyState minHeight="283px" heading={`${business.name} is not accepting online payments at the
                moment.`} callToAction={<Text>
                    Please view your invoice for further instructions or{' '}
                    <Link as={ReachLink} to={`/contact`}>
                      contact us
                    </Link>
                  </Text>} />}
          </BorderBox>
        </Box> : <Box gridArea="methods">
          <BorderBox textAlign="center">
            <CroppedText fontSize={2}>
              Your account has no outstanding invoices. Thank you.
            </CroppedText>
          </BorderBox>
        </Box>}
      <Box gridArea="summary" data-sentry-element="Box" data-sentry-source-file="component.tsx">
        <BorderBox p={0} data-sentry-element="BorderBox" data-sentry-source-file="component.tsx">
          <Box px={3} py={4} borderBottomWidth="1px" borderBottomStyle="solid" borderBottomColor="gray.1" data-sentry-element="Box" data-sentry-source-file="component.tsx">
            <Heading fontSize={2} fontWeight={600} data-sentry-element="Heading" data-sentry-source-file="component.tsx">
              Statement summary
            </Heading>
          </Box>

          {/* <Flex justifyContent="space-between" p={3}>
            <CroppedText>Total</CroppedText>
            <CroppedText>{currencyFormat(total_amount, currency)}</CroppedText>
           </Flex>
           <Flex justifyContent="space-between" p={3}>
            <CroppedText>Paid</CroppedText>
            <CroppedText>{currencyFormat(paid_amount, currency)}</CroppedText>
           </Flex> */}

          <Flex justifyContent="space-between" px={3} py={4}
        // borderTopWidth="1px"
        // borderTopStyle="solid"
        // borderTopColor="gray.1"
        data-sentry-element="Flex" data-sentry-source-file="component.tsx">
            <CroppedText fontWeight={600} data-sentry-element="CroppedText" data-sentry-source-file="component.tsx">Total due</CroppedText>
            <CroppedText fontWeight={600} data-sentry-element="CroppedText" data-sentry-source-file="component.tsx">
              {currencyFormat(total_due, currency)}
            </CroppedText>
          </Flex>

          <Box p={3} borderTopWidth="1px" borderTopStyle="solid" borderTopColor="gray.1" data-sentry-element="Box" data-sentry-source-file="component.tsx">
            <Button as="a" variant="outlined" color="gray" rel="noopener noreferrer" target="_blank" href={settings.bypassPWAScope(`/s/${toBase58(clientId)}?download=false` + (locationId ? `&location_id=${locationId}` : ''))} data-sentry-element="Button" data-sentry-source-file="component.tsx">
              View statement
            </Button>
          </Box>
        </BorderBox>
      </Box>
    </Grid>;
};
export default PayAccount;