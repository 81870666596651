import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { DateTime, WeekdayNumbers } from 'luxon';
import React from 'react';
import Button, { ButtonGroup } from '../button';
import Dropdown from '../dropdown';
import { Box, Flex, Grid } from '../grid';
import { CroppedText } from '../typography';
import { getCalendarPage } from '../utils/time';
const getMonths = (year, month, nowDt) => {
  const today = nowDt;
  let currentMonthIdx = 0;
  const calendarMonths = [];
  while (currentMonthIdx < 12) {
    if (currentMonthIdx % 3 === 0) {
      calendarMonths.push([]);
    }
    const date = DateTime.fromJSDate(new Date(year, month + currentMonthIdx, 1));
    const data = {
      month: date.month,
      showYear: false,
      date: date.toISO().substring(0, 19),
      current: date.month === today.month
    };
    calendarMonths[Math.floor(currentMonthIdx / 3)].push(data);
    currentMonthIdx++;
  }
  return calendarMonths;
};
type CalendarPickerProps = {
  mode: string;
  value: string;
  onChange: (string) => void;
  disablePast?: boolean;
  className?: string;
  language: string;
  timezone: string;
  variant?: 'solid' | 'flat' | 'outlined';
  color?: string;
};
const CalendarPicker = ({
  mode,
  value: inputDate,
  onChange: onChange_,
  language,
  timezone,
  variant = 'solid',
  color = 'secondary'
}: CalendarPickerProps) => {
  const nowDt = DateTime.utc().setZone(timezone).setZone('utc', {
    keepLocalTime: true
  });
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownDate, setDropdownDate] = React.useState(inputDate);
  if (inputDate?.endsWith('Z')) {
    console.error('Input date needs to be local');
  }
  const inputDateTime = DateTime.fromISO(inputDate);
  let startDateTime = inputDateTime.startOf('day');
  let endDateTime = inputDateTime.endOf('day');
  const showWeek = mode === 'week' || mode === 'agenda';
  if (showWeek) {
    startDateTime = startDateTime.weekday === 0 as WeekdayNumbers ? startDateTime.minus({
      day: 1
    }).set({
      weekday: 1
    }) : startDateTime.set({
      weekday: 1
    });
    endDateTime = startDateTime.plus({
      day: 6
    }).endOf('day');
  }
  React.useEffect(() => {
    setDropdownDate(inputDate);
  }, [inputDate]);
  const isSelectedDay = (date_: string) => {
    const date = DateTime.fromISO(date_);
    if (startDateTime <= date && date <= endDateTime) {
      return true;
    }
    return false;
  };
  const setOpen = val => {
    setIsOpen(val);
    setDropdownDate(inputDate);
  };
  const open = () => setOpen(true);
  const close = () => setOpen(false);
  const onChange = v => {
    onChange_(v);
    close();
  };
  const changeDate = pos => {
    if (showWeek) {
      onChange(inputDateTime.plus({
        weeks: pos
      }).toISO().substring(0, 19));
    } else if (mode === 'month') {
      onChange(inputDateTime.plus({
        months: pos
      }).toISO().substring(0, 19));
    } else {
      onChange(inputDateTime.plus({
        days: pos
      }).toISO().substring(0, 19));
    }
  };
  const changeMiniDate = pos => {
    if (mode === 'month') {
      setDropdownDate(DateTime.fromISO(dropdownDate).plus({
        years: pos
      }).toISO().substring(0, 19));
    } else {
      setDropdownDate(DateTime.fromISO(dropdownDate).plus({
        month: pos
      }).toISO().substring(0, 19));
    }
  };
  const label = (dt: string, small = true) => {
    const days = showWeek ? 7 : 1;
    const dateStart = DateTime.fromISO(dt);
    const dateEnd = dateStart.plus({
      days: days - 1
    });
    let year = '';
    const crossYear = dateEnd.year !== nowDt.year;
    if (mode === 'month') {
      if (crossYear) {
        year = small ? 'yy' : ', y';
      }
      return dateStart.toFormat((small ? 'MMM' : 'MMMM') + year);
    }
    if (crossYear && !small) {
      year = ', y';
    }
    if (!small && dateEnd.month !== dateStart.month) {
      return dateStart.toFormat('d MMM') + dateEnd.toFormat(` - d MMM${year}`);
    }
    if (dateEnd.day !== dateStart.day) {
      return dateStart.toFormat('d ') + dateEnd.toFormat((small ? ' - d MMM' : ' - d MMMM') + year);
    }
    return dateStart.toFormat((small ? 'EEE d MMM' : 'EEEE d MMMM') + year);
  };
  const calendarMonths = React.useMemo(() => getMonths(DateTime.fromISO(dropdownDate).year, 0, nowDt), [dropdownDate, nowDt]);
  const calendarWeeks = React.useMemo(() => getCalendarPage(dropdownDate, timezone).weeks, [dropdownDate, timezone]);
  return <Dropdown isOpen={isOpen} close={close} open={open} autoClose={false} toggle={props => <ButtonGroup>
          <Button variant={variant} color={color} onClick={() => changeDate(-1)} className="side" iconLeft={faAngleLeft} />

          <Button px={[1, 3]} variant={variant} color={color} className="middle" {...props}>
            <CroppedText className="hide-for-medium" sx={{
        whiteSpace: 'nowrap'
      }}>
              {label(inputDate, true)}
            </CroppedText>
            <CroppedText className="show-for-medium">
              {label(inputDate, false)}
            </CroppedText>
          </Button>
          <Button variant={variant} color={color} onClick={() => changeDate(1)} className="side" iconLeft={faAngleRight} />
        </ButtonGroup>} data-sentry-element="Dropdown" data-sentry-component="CalendarPicker" data-sentry-source-file="index.tsx">
      <Box p={2} sx={{
      border: '1px solid rgba(27, 31, 35, 0.15)',
      boxShadow: 'medium',
      backgroundColor: 'background',
      textAlign: 'center',
      borderRadius: 2,
      maxWidth: '250px',
      zIndex: 1
    }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        {mode === 'month' ? <>
            <ButtonGroup>
              <Button variant={variant} color={color} onClick={() => changeMiniDate(-1)} className="side" iconLeft={faAngleLeft} />
              <Button width="80%">
                {DateTime.fromISO(dropdownDate).toFormat('y')}
              </Button>
              <Button variant={variant} color={color} onClick={() => changeMiniDate(1)} className="side" iconLeft={faAngleRight} />
            </ButtonGroup>
            <Box pt={2} sx={{
          position: 'relative',
          height: '100%'
        }}>
              <Grid gridTemplateColumns="repeat(3, 1fr)" gridGap={0}>
                {calendarMonths.map((row, idx) => <React.Fragment key={idx}>
                    {row.map(({
                date,
                current
              }, idx) => <Flex key={idx} width="32px" height="32px" justifyContent="center" alignItems="center" role="button" tabIndex={0} onClick={() => onChange(date)} onKeyPress={e => e.key === 'Enter' && onChange(date)} sx={{
                cursor: 'pointer',
                color: 'text',
                ...(inputDateTime.hasSame(DateTime.fromISO(date), 'month') ? {
                  backgroundColor: 'primary',
                  color: 'white'
                } : {}),
                ...(current ? {
                  backgroundColor: 'background',
                  fontWeight: 'heading',
                  'a:first-of-type': {
                    borderWidth: '2px',
                    borderColor: 'primary',
                    borderStyle: 'solid'
                  },
                  color: 'primary'
                } : {})
              }}>
                        <CroppedText>
                          {DateTime.fromISO(date).toFormat('MMM')}
                        </CroppedText>
                      </Flex>)}
                  </React.Fragment>)}
              </Grid>
            </Box>
          </> : <>
            <ButtonGroup>
              <Button variant={variant} color={color} onClick={() => changeMiniDate(-1)} iconLeft={faAngleLeft} />
              <Button variant={variant} color={color} width="80%">
                {DateTime.fromISO(DateTime.fromISO(dropdownDate).hasSame(nowDt, 'year') ? dropdownDate : dropdownDate).toFormat('MMMM')}
              </Button>

              <Button variant={variant} color={color} onClick={() => changeMiniDate(1)} iconLeft={faAngleRight} />
            </ButtonGroup>
            <Box pt={2} sx={{
          position: 'relative',
          height: '100%'
        }}>
              <Grid gridTemplateColumns="repeat(7, 1fr)" gridGap={0}>
                {[1, 2, 3, 4, 5, 6, 7].map((weekday: WeekdayNumbers) => <Flex key={weekday} width="32px" height="32px" justifyContent="center" alignItems="center">
                    <CroppedText>
                      {nowDt.setLocale(language).set({
                  weekday
                }).toFormat('ccc')}
                    </CroppedText>
                  </Flex>)}

                {calendarWeeks.map((week, idx) => <React.Fragment key={idx}>
                    {week.map(({
                date,
                inPast,
                current,
                day,
                currentMonth
              }, idx2) => <Flex key={idx2} width="32px" height="32px" justifyContent="center" alignItems="center" role="button" tabIndex={0} onClick={() => onChange(date)} onKeyPress={e => e.key === 'Enter' && onChange(date)} sx={{
                cursor: 'pointer',
                ...(!currentMonth && !isSelectedDay(date) ? {
                  color: 'gray.6'
                } : {}),
                ...(inPast ? {
                  color: 'gray.6',
                  textDecoration: 'line-through'
                } : {}),
                ...(isSelectedDay(date) ? {
                  backgroundColor: 'primary',
                  color: 'white'
                } : {}),
                ...(current ? {
                  backgroundColor: 'background',
                  fontWeight: 'heading',
                  borderColor: 'primary',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  color: 'primary'
                } : {})
              }}>
                          <CroppedText>{day}</CroppedText>
                        </Flex>)}
                  </React.Fragment>)}
              </Grid>
            </Box>
          </>}
      </Box>
    </Dropdown>;
};
export default CalendarPicker;