import { useLocationId } from './hooks';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ReachLink } from 'react-router-dom';
import Button from 'shared/button';
import { Box, Container, Flex, Grid } from 'shared/grid';
import { StandardModal } from 'shared/modal';
import PoweredBy from 'shared/powered-by';
import { logout } from 'shared/redux/session/actions';
import { updatePreferences } from 'shared/redux/user-preferences/actions';
import { Heading } from 'shared/typography';
import { languages } from 'shared/utils/constants';
import { getCdnImageUrlFromId } from 'shared/utils/images';
import { useAppDispatch, useAppSelector } from './store';
import { Avatar } from 'shared/avatar';
import { Text } from 'shared/typography';
import { faAddressBook, faLanguage } from '@fortawesome/pro-solid-svg-icons';
export const Header = () => {
  const dispatch = useAppDispatch();
  const [isLanguageModalOpen, setLanguageModalOpen] = React.useState(false);
  const setLanguage = React.useCallback(language => {
    dispatch(updatePreferences({
      language
    }));
  }, [dispatch]);
  const isSessionValid = useAppSelector(state => state.session.tokenExp > new Date().getTime() / 1000);
  const [locationId] = useLocationId();
  const business = useAppSelector(state => state.businesses.data[state.public.businessId]);
  return <>
      <Flex bg="background" width="100%" justifyContent={['space-between']} alignItems="center" boxShadow="medium" zIndex={3} flexWrap={['wrap', 'nowrap']} data-sentry-element="Flex" data-sentry-source-file="layout.tsx">
        <Container py={[3, 3]} px={['24px', '24px']} className="container" data-sentry-element="Container" data-sentry-source-file="layout.tsx">
          <Flex justifyContent="space-between" alignItems="center" flexWrap={['wrap', 'nowrap']} sx={{
          gap: 2
        }} data-sentry-element="Flex" data-sentry-source-file="layout.tsx">
            <Flex className="logo-and-name" data-sentry-element="Flex" data-sentry-source-file="layout.tsx">
              <Flex as={ReachLink} justifyContent="center" alignItems="center" to={`/` + (locationId ? `?lid=${locationId}` : '')} sx={{
              gap: 3
            }} data-sentry-element="Flex" data-sentry-source-file="layout.tsx">
                {business.logo_image_id && <Box position={['absolute', 'relative']} top={['90px', 0]} left={['24px', 0]} flex="0 1 150px">
                    <Box sx={{
                  position: 'absolute',
                  bottom: '0px',
                  zIndex: 1
                }}>
                      <Avatar name="" color="primary" size={150} image={business.logo_image_id ? getCdnImageUrlFromId(business.logo_image_id) : null} />
                    </Box>

                    <Box width="150px" height="60px" mr={3}></Box>
                  </Box>}

                <Box flex="1 1 100px" data-sentry-element="Box" data-sentry-source-file="layout.tsx">
                  <Heading as="h1" fontSize={3} color="text" sx={{
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflowWrap: 'break-word'
                }} data-sentry-element="Heading" data-sentry-source-file="layout.tsx">
                    {business.name}
                  </Heading>
                </Box>
              </Flex>
            </Flex>

            <Flex sx={{
            gap: 0
          }} data-sentry-element="Flex" data-sentry-source-file="layout.tsx">
              {/* {business.url && (
                <Button
                  as="a"
                  color="gray"
                  variant="flat"
                  display={['none', 'block']}
                  target="_blank"
                  href={business.url}
                  iconLeft={faHome}
                />
               )} */}

              {isSessionValid && <Button variant="flat" onClick={() => dispatch(logout())}>
                  Sign out
                </Button>}
              <Button as={ReachLink} variant="flat" color="gray" to={`/contact?lid=${locationId}`} iconLeft={faAddressBook} data-sentry-element="Button" data-sentry-source-file="layout.tsx">
                <Text display={['none', 'inline']} data-sentry-element="Text" data-sentry-source-file="layout.tsx">
                  <FormattedMessage id="Contact us" defaultMessage="Contact us" data-sentry-element="FormattedMessage" data-sentry-source-file="layout.tsx" />
                </Text>
              </Button>

              <Button sx={{
              top: 0,
              right: 0
            }} position={['absolute', 'static']} color="gray" variant="flat" onClick={() => setLanguageModalOpen(true)} iconLeft={faLanguage} data-sentry-element="Button" data-sentry-source-file="layout.tsx" />
            </Flex>
          </Flex>
        </Container>
      </Flex>

      <StandardModal close={() => setLanguageModalOpen(false)} isOpen={isLanguageModalOpen} title={<FormattedMessage id="Public.selectLanguage" defaultMessage="Select a language" />} data-sentry-element="StandardModal" data-sentry-source-file="layout.tsx">
        {() => <Grid>
            {Object.entries(languages).map(([code, language], idx) => <Button variant="outlined" key={idx} width="100%" onClick={() => {
          setLanguage(code);
          setLanguageModalOpen(false);
        }}>
                {language}
              </Button>)}
          </Grid>}
      </StandardModal>
    </>;
};
export const BareLayout = ({
  children
}) => {
  const business = useAppSelector(state => state.businesses.data[state.public.businessId]);
  return <>
      <Box sx={{
      height: '10rem',
      backgroundColor: '#fff',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      position: 'relative',
      bg: 'gray.1',
      backgroundImage: business.cover_image_id ? `url(${getCdnImageUrlFromId(business.cover_image_id)})` : `linear-gradient(
                to right,
                rgba(0, 0, 0, 0.2), 
                transparent)`
    }} data-sentry-element="Box" data-sentry-source-file="layout.tsx" />
      <Header data-sentry-element="Header" data-sentry-source-file="layout.tsx" />
      <Container px={[1, '24px']} py={['24px']} flex={[1, 0]} display="flex" sx={{
      flexDirection: 'row',
      '& > *': {
        flexGrow: 1
      }
    }} data-sentry-element="Container" data-sentry-source-file="layout.tsx">
        {children}
      </Container>
      <PoweredBy medium="embed" my={3} data-sentry-element="PoweredBy" data-sentry-source-file="layout.tsx" />
    </>;
};
const Layout = ({
  children
}) => {
  // const isServer = typeof window === 'undefined';
  // const inIframe = isServer ? false : window.location !== window.top.location;
  const business = useAppSelector(state => state.businesses.data[state.public.businessId]);
  return <>
      <Box sx={{
      height: '10rem',
      backgroundColor: '#fff',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      position: 'relative',
      bg: 'gray.1',
      backgroundImage: business.cover_image_id ? `url(${getCdnImageUrlFromId(business.cover_image_id)})` : `linear-gradient(
                to right,
                rgba(0, 0, 0, 0.2), 
                transparent)`
    }} data-sentry-element="Box" data-sentry-source-file="layout.tsx" />
      <Header data-sentry-element="Header" data-sentry-source-file="layout.tsx" />

      <Flex flex="1" data-sentry-element="Flex" data-sentry-source-file="layout.tsx">
        <Container backgroundColor="bodyBackground" flex="1" display="flex" flexDirection="column" data-sentry-element="Container" data-sentry-source-file="layout.tsx">
          {children}
          <PoweredBy pt={2} data-sentry-element="PoweredBy" data-sentry-source-file="layout.tsx" />
        </Container>
      </Flex>
    </>;
};
export default Layout;