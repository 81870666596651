import App from './app';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routes from './routes';
import { makeStore } from './store';
const router = createBrowserRouter(routes);
const {
  store,
  persistor
} = makeStore();
ReactDOM.hydrateRoot(document, <StrictMode>
    <App store={store} persistor={persistor}>
      <RouterProvider router={router} />
    </App>
  </StrictMode>);