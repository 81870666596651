import { faImage, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'cropperjs/dist/cropper.css';
import React, { SyntheticEvent } from 'react';
import Button, { ButtonGroup } from 'shared/button';
import { CroppedText } from 'shared/typography';
import { Box, Flex, Grid } from '../grid';
import Loader from '../loader';
import Modal from '../modal';
import xml from './xml';
const Cropper = React.lazy(() => import('shared/react-cropper'));
async function smilConverter(animation) {
  const svgFactory = (await import('shared/bodymovin-to-smil/src/svg/svg')).default;
  return new Promise<string>(function (resolve, reject) {
    const _svg = svgFactory();
    _svg.processAnimation(animation).then(_svg.exportNode).then(function (svgNode) {
      const format = '';
      const xmlString = xml(svgNode, format);
      resolve(xmlString);
    }).catch(function (err) {
      reject(err.stack);
    });
  });
}
interface ImageInputProps {
  className?: string;
  aspectRatio?: number;
  initialAspectRatio?: number;
  value: string;
  onChange: (string) => void;
  onLoad?: (event: SyntheticEvent<HTMLImageElement, Event>) => void;
  forceJpegWhenOver?: number;
  allowSVG?: boolean;
  sx?: any;
}
const ImageInput = ({
  className,
  aspectRatio,
  initialAspectRatio,
  value,
  onChange,
  onLoad,
  forceJpegWhenOver = 4000,
  allowSVG = aspectRatio === undefined,
  sx
}: ImageInputProps) => {
  const fileInputRef = React.useRef<any>(null);
  const cropperRef = React.useRef<any>(null);
  const [cropperImageData, setCropperImageData] = React.useState<any>(null);
  const clear = React.useCallback(() => {
    onChange('');
  }, [onChange]);
  const fileSelected = React.useCallback(file => {
    const reader = new FileReader();
    reader.onload = async e => {
      if (file.type === 'application/json') {
        const data = JSON.parse(e.target.result as string);
        const svg = await smilConverter(data);
        const b64 = 'data:image/svg+xml;base64,' + window.btoa(svg);
        onChange(b64);
        return;
      } else if (file.type === 'image/svg+xml') {
        onChange(e.target.result as string);
      } else {
        setCropperImageData(e.target.result as string);
      }
    };
    if (!allowSVG && !['image/jpeg', 'image/png'].includes(file.type)) {
      return;
    }
    if (file.type == 'application/json') {
      reader.readAsText(file);
    } else {
      reader.readAsDataURL(file);
    }
  }, [allowSVG, onChange]);
  const crop = React.useCallback(() => {
    const originalMimeType = /data:(.*);/.exec(cropperImageData || '')[1];
    const {
      width,
      height
    } = cropperRef.current.cropper.getCropBoxData();
    const mimeType = Math.max(width, height) > forceJpegWhenOver ? 'image/jpeg' : originalMimeType;

    // const mimeType = originalMimeType;

    const canvas: HTMLCanvasElement = cropperRef.current.cropper.getCroppedCanvas({
      maxWidth: 2048,
      maxHeight: 2048,
      fillColor: mimeType === 'image/jpeg' ? '#fff' : undefined
    });
    const dataUrl = canvas.toDataURL(mimeType);
    onChange(dataUrl);
    setCropperImageData(null);
  }, [cropperImageData, forceJpegWhenOver, onChange]);
  const skip = React.useCallback(() => {
    onChange(cropperImageData);
    setCropperImageData(null);
  }, [cropperImageData, onChange]);
  return <>
      <Box width="100%" alignItems="center" justifyContent="center" className={className} flexGrow={1} borderRadius={2} position="relative" borderColor="gray.3" borderWidth={1} borderStyle="solid" sx={{
      ...sx,
      cursor: 'pointer'
    }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        {value && <Box as="button" type="button" onClick={clear} sx={{
        zIndex: 3,
        position: 'absolute',
        top: 2,
        right: 2,
        color: 'text',
        textShadow: '0px 0px 10px #ffffff'
      }}>
            <CroppedText>
              <FontAwesomeIcon icon={faTimes} size="lg" />
            </CroppedText>
          </Box>}

        <Box as="button" type="button" onClick={() => fileInputRef.current.click()} sx={{
        cursor: 'pointer',
        zIndex: 2,
        position: 'absolute',
        top: 0,
        display: 'block',
        width: '100%',
        height: '100%'
      }} data-sentry-element="Box" data-sentry-source-file="index.tsx" />

        {value ? <Box as="img" onLoad={onLoad} src={value} width="100%" /> : <Flex justifyContent="center" alignItems="center" width="100%" height="20em" textAlign="center" color="gray.6">
            <Box>
              <FontAwesomeIcon icon={faImage} size="3x" />
              <Box color="gray.6">Select an image.</Box>
            </Box>
          </Flex>}
      </Box>

      <Box as="input" ref={fileInputRef} sx={{
      display: 'none'
    }} type="file" accept={`image/jpeg,image/png,${allowSVG ? 'application/json,image/svg+xml' : ''}`} name="imagefile" value={''} onChange={(e: any) => fileSelected(e.target.files[0])} data-sentry-element="Box" data-sentry-source-file="index.tsx" />

      <Modal close={() => setCropperImageData(null)} isOpen={!!cropperImageData} props={{
      cropperRef,
      imageData: cropperImageData,
      aspectRatio,
      initialAspectRatio,
      crop
    }} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
        {({
        cropperRef,
        imageData,
        aspectRatio,
        initialAspectRatio,
        crop
      }) => <Grid p={2} gridGap={2} sx={{
        img: {
          maxWidth: '100%'
        }
      }}>
            <Box width="100%" sx={{
          position: 'relative',
          '& > .cropper': {
            maxWidth: '100%',
            width: '100%',
            height: '400px'
          }
        }}>
              <React.Suspense fallback={<Loader />}>
                <Cropper className="cropper" ref={cropperRef} src={imageData} initialAspectRatio={initialAspectRatio} aspectRatio={aspectRatio} guides={false} background={false} minCropBoxHeight={10} minCropBoxWidth={10} autoCrop={true} autoCropArea={1} viewMode={1} responsive={true} restore={true} dragMode={'move'} zoomTo={0} />
              </React.Suspense>
            </Box>
            <ButtonGroup width="100%">
              <Button onClick={crop}>Crop</Button>
              {!aspectRatio && <Button color="secondary" onClick={skip}>
                  Skip crop
                </Button>}
            </ButtonGroup>
          </Grid>}
      </Modal>
    </>;
};
export default ImageInput;