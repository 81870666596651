import { ViewErrorBoundary } from 'shared/error-boundry';
import { BareLayout } from '../../layout';
import { BorderBox } from '../shared';
import Component from './component';
const ViewWrapper = props => <BareLayout data-sentry-element="BareLayout" data-sentry-component="ViewWrapper" data-sentry-source-file="index.tsx">
    <ViewErrorBoundary data-sentry-element="ViewErrorBoundary" data-sentry-source-file="index.tsx">
      <BorderBox pt={[0, '24px']} data-sentry-element="BorderBox" data-sentry-source-file="index.tsx">
        <Component {...props} data-sentry-element="Component" data-sentry-source-file="index.tsx" />
      </BorderBox>
    </ViewErrorBoundary>
  </BareLayout>;
export default ViewWrapper;