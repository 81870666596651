export const FEATURES = {
  client_fields: 'Custom client profiles',
  forms: 'Forms',
  invoices: 'Invoicing',
  eva_mode: 'EVA mode',
  video_calls: 'Video calls',
  promo_codes: 'Promo codes',
  client_notes: 'Documents',
  repeat_bookings: 'Repeat bookings',
  multiple_clients: 'Multi-client bookings',
  attachments: 'Email attachments & file storage',
  ses: 'Amazon SES',
  reports: 'Reports',
  emguidance: 'EMGuidance script integration',
  client_requests: 'Client Requests',
  yoco: 'Yoco payments (in alpha)',
};

export const CORE_FEATURE_DESCRIPTIONS = [
  'Reminders and notifications',
  'Message replies',
  'Online client bookings',
  'Embed on your website',
  'Sync with external calendar',
];

export const languages = {
  en: 'English',
  fr: 'Français',
};

export const SYSTEM_CURRENCIES = ['ZAR', 'USD'];
export const IN_READ_ONLY_MODE = false;
export const ONBOARDING_ENABLED = true;

export const EMPTY_ARRAY = Object.freeze([]);
export const EMPTY_OBJECT = Object.freeze({});
export const EMAIL_ATTACHMENTS_SIZE_LIMIT_BYTES = 10 * Math.pow(2, 20);
export const CLIENT_REQUEST_TYPES = Object.freeze({
  document: 'document',
  booking: 'booking',
});

export const UPLOAD_PATH_PREFIX = '/api2/uploads/';
export const CONFIRM_PUBLIC_BOOKING_POLICY = Object.freeze({
  payment: 'payment',
  manual: 'manual',
  automatic: 'automatic',
});
