import React from 'react';
import { DateTime } from 'luxon';
import Input from '../input';
import { BoxProps } from '../grid';

// Only hours and minutes, no timezone or UTC
const validateRegex = /^\d{4}-[01]\d-[0-3]\d$/;
interface DateOnlyPickerProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'prefix'> {
  value: string;
  onChange: (value: string) => any;
  className?: string;
  style?: Record<string, any>;
  earliest?: string;
  latest?: string;
  disabled?: boolean;
  required?: boolean;
  tabIndex?: number;
  invalid?: boolean;
  label?: any;
  error?: any;
  forceText?: boolean;
  sx?: BoxProps['sx'];
}
const isValidDate = v => (v || '').match(validateRegex) && DateTime.fromISO(v).isValid;

// const toValue = (valueText, displayTimezone) =>

const DateOnlyPicker = ({
  ref,
  value,
  onChange,
  earliest,
  latest,
  onBlur,
  forceText,
  ...rest
}: DateOnlyPickerProps & {
  ref?: React.Ref<HTMLInputElement>;
}) => {
  const dateInputType = forceText ? 'text' : 'date';
  const [displayText, setDisplayText_] = React.useState('');
  const setDisplayText = React.useCallback(displayText => {
    setDisplayText_(displayText);
    if (!displayText) {
      onChange('');
    }
    if (displayText && !isValidDate(displayText)) {
      return;
    }
    onChange(displayText.substring(0, 10));
  }, [onChange]);
  React.useEffect(() => {
    if (earliest && earliest.substring(0, 10) > value) {
      onChange(earliest);
    } else if (latest && latest.substring(0, 10) < value) {
      onChange(latest);
    } else if (isValidDate(value)) {
      setDisplayText_(value);
    }
  }, [value, earliest, latest, onChange]);
  return <Input {...rest} max="2999-12-31" ref={ref} placeholder="YYYY-MM-DD" type={dateInputType} value={displayText} onBlur={e => {
    setDisplayText_(value);
    if (onBlur) {
      onBlur(e);
    }
  }} onChange={v => setDisplayText(v.target.value)} data-sentry-element="Input" data-sentry-component="DateOnlyPicker" data-sentry-source-file="index.tsx" />;
};
export default React.memo(DateOnlyPicker);