import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { Link as ReachLink } from 'react-router-dom';
import { Box, BoxProps, Flex } from 'shared/grid';
import IconWrapper from 'shared/icon-wrapper';
import { Heading } from 'shared/typography';
export const BorderBox = ({
  children,
  ...rest
}: BoxProps) => <Box bg="background" borderRadius={2} borderWidth="1px" borderStyle="solid" borderColor={['background', 'borderColor']} p={'24px'} {...rest} data-sentry-element="Box" data-sentry-component="BorderBox" data-sentry-source-file="shared.tsx">
    {children}
  </Box>;
export const BookStepHeading = ({
  children,
  backTo
}) => {
  return <Flex alignItems="center" sx={{
    gap: 3
  }} data-sentry-element="Flex" data-sentry-component="BookStepHeading" data-sentry-source-file="shared.tsx">
      <IconWrapper flexShrink={0} as={ReachLink} to={backTo} icon={faArrowLeft} size={1} data-sentry-element="IconWrapper" data-sentry-source-file="shared.tsx" />
      <Heading fontSize={3} data-sentry-element="Heading" data-sentry-source-file="shared.tsx">{children}</Heading>
    </Flex>;
};