import React from 'react';
import { useSelector } from 'react-redux';
import { EMPTY_OBJECT, FEATURES } from 'shared/utils/constants';
const EMPTY_ARRAY = [];
type featureType = keyof typeof FEATURES;
export function useFeatureFlags(): featureType[] {
  const businessId = useSelector((state: any) => {
    if (state.userPreferences?.currentBusinessId && state.userPreferences?.currentBusinessId !== '0') {
      return state.userPreferences?.currentBusinessId;
    } else {
      return state.public?.businessId;
    }
  });
  const subscriptionData = useSelector((state: any) => state.subscriptions?.data || EMPTY_OBJECT);
  const businessData = useSelector((state: any) => state.businesses.data);
  const business = businessData[businessId];
  const planData = useSelector((state: any) => state.plans?.data || EMPTY_OBJECT);
  const planFeatures = React.useMemo(() => {
    const subscriptions = Object.values<any>(subscriptionData);
    const subscription = subscriptions.filter(s => !['cancelled', 'unpaid'].includes(s.status)).find(s => s.business_id === businessId);
    if (subscription) {
      const plan = planData[subscription.plan_id];
      if (!plan) {
        return [];
      }
      return plan.features;
    }
    return [];
  }, [businessId, planData, subscriptionData]);
  const featureFlags = React.useMemo(() => business?.feature_flags || EMPTY_ARRAY, [business?.feature_flags]);
  const finalFlags = React.useMemo(() => [...featureFlags, ...planFeatures], [featureFlags, planFeatures]);
  if (__TARGET_ENV__ !== 'production') {
    finalFlags.push('documents');
  }
  return finalFlags;
}
export function useHasFeature(feature: featureType): boolean {
  const featureFlags = useFeatureFlags();
  return featureFlags.includes(feature);
}